import axios from "axios";
import { toast } from "react-toastify";

export const apiCall = async (payload) => {
  return await axios({
    method: payload.method,
    url: payload.url,
    data: payload?.data || {},
    headers: payload.headers
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return toast.error(error.message || "Errore sconosciuto");
    });
};
