import { FormControlLabel, Grid, Switch } from "@mui/material";
import { DiscountBox } from "../DiscountBox/DiscountBox";
import "../../Container/CouponGenerator/CouponGenerator.css";
import React, { useState } from "react";

export function DiscountSwitch({ data, key }) {
  const [curr, setCurr] = useState(data.defaultValue);
  const onChangeHandler = () => {
    setCurr(!curr);
  };
  return (
    <Grid item xs={3} key={key} className={"custom-input-grid"}>
      <FormControlLabel
        required={data.required}
        control={<Switch defaultChecked={data.defaultValue} name={data.name} />}
        label={data.label}
        onChange={onChangeHandler}
      />
      <DiscountBox data={data} key={key} changeValue={curr}></DiscountBox>
    </Grid>
  );
}
