export const inputData = [
  {
    label: "Nome",
    name: "name",
    type: "string",
    required: true,
  },
  {
    label: "Descrizione",
    name: "description",
    type: "string",
    required: true,
  },
  {
    label: "Data inizio",
    name: "date_from",
    type: "date",
    required: true,
  },
  {
    label: "Data fine",
    name: "date_to",
    type: "date",
    required: true,
  },
  {
    label: "Quantità per singolo codice",
    name: "quantity",
    type: "int",
    defaultValue: 1,
    required: true,
  },
  {
    label: "Quantità per utente",
    name: "quantity_per_user",
    type: "int",
    defaultValue: 1,
    required: true,
  },
  {
    label: "Priorità",
    name: "priority",
    type: "int",
    defaultValue: 1,
    required: true,
  },
  {
    label: "Uso Parziale",
    name: "partial_use",
    type: "bool",
    defaultValue: false,
    required: true,
  },
  {
    label: "Escludi merce scontata",
    name: "reduction_exclude_special",
    type: "bool",
    defaultValue: true,
    required: true,
  },
  {
    label: "Compatibilità regole carrello",
    name: "cart_rule_restriction",
    type: "bool",
    defaultValue: true,
    required: true,
  },
  {
    label: "Attivo",
    name: "active",
    type: "bool",
    defaultValue: true,
    required: true,
  },
  {
    label: "Percentuale",
    name: "reduction_switch",
    type: "bool",
    defaultValue: true,
    required: true,
  },
  {
    label: "Spedizione gratuita",
    name: "free_shipping",
    type: "bool",
    defaultValue: false,
    required: true,
  },
  {
    label: "Numero totale codici",
    name: "couponNumber",
    type: "int",
    defaultValue: 1,
    min: 1,
    max: 1000,
    required: true,
  },
  {
    label: "Prefisso",
    name: "prefix",
    type: "string",
    required: true,
  },
  {
    label: "Lunghezza codice",
    name: "stringLength",
    type: "int",
    min: 5,
    max: 10,
    required: true,
  },
];
