import { Grid, InputAdornment, TextField } from "@mui/material";
import "../../Container/CouponGenerator/CouponGenerator.css";
import PercentIcon from "@mui/icons-material/Percent";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";

export function DiscountBox({ data, key, changeValue }) {
  if (changeValue) {
    return (
      <Grid item xs={3} key={key} className={"custom-input-grid"}>
        <TextField
          label="Percentuale"
          name="reduction_percent"
          type="number"
          required="true"
          className={"custom-input"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <PercentIcon />
              </InputAdornment>
            ),
          }}
          defaultValue="10"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    );
  } else {
    return (
      <Grid item xs={3} key={key} className={"custom-input-grid"}>
        <TextField
          label="Importo"
          name="reduction_amount"
          type="number"
          required="true"
          className={"custom-input"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <EuroSymbolIcon />
              </InputAdornment>
            ),
          }}
          defaultValue="6"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    );
  }
}
