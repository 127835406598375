import {
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "./TestFantasiastore.css";
import { apiCall } from "./apiCall";
import { toast } from "react-toastify";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const lambdaUrl =
  "https://qautggeagxjmx2oi6ul3pfdybi0gceri.lambda-url.eu-central-1.on.aws/";

const TestFantasiastore = () => {
  const [bearer, setBearer] = useState("");
  const [postObject, setPostObject] = useState({
    reason: "",
    username: "",
    dateFrom: moment.now(),
    dateTo: moment.now(),
  });
  const [scheduleData, setScheduleData] = useState([]);

  const changeItem = (id, name, value) => {
    console.log(id, name, value);

    let obj = [...scheduleData];

    obj.forEach((el) => {
      console.log(el.id, id);
      if (el.id === id) {
        console.log(1);
        el[name] = value;
      }
    });
    setScheduleData(obj);
  };

  const apiHandler = async (method, data) => {
    if (bearer === "") {
      return toast.error("Token vuoto");
    }
    if (method === "POST") {
      if (data.reason === "") {
        return toast.error("Task mancante");
      }
      if (data.username === "") {
        return toast.error("Utente mancante");
      }
    }
    if (data?.dateFrom && data?.dateTo) {
      data.dateFrom = moment(data.dateFrom).toISOString();
      data.dateTo = moment(data.dateTo).toISOString();
    }
    const response = await apiCall({
      method: method,
      url: lambdaUrl,
      data: data,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    });

    if (method !== "GET") {
      apiHandler("GET");
    } else {
      toast.success("OK");
      setScheduleData(response?.data?.data?.Items || []);
    }
  };

  return (
    <Container className={"container"}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <h1>Prenota</h1>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <TextField
            label="Token"
            value={bearer}
            onChange={(ev) => {
              setBearer(ev.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper elevation={2} className={"paper"}>
        <Grid
          container
          justifyContent={"center"}
          className={"create-new-container"}
        >
          <TextField
            label="Task"
            size="small"
            value={postObject.reason}
            onChange={(ev) =>
              setPostObject({ ...postObject, reason: ev.target.value })
            }
          />
          <TextField
            label="Utente"
            size="small"
            value={postObject.username}
            onChange={(ev) =>
              setPostObject({ ...postObject, username: ev.target.value })
            }
          />
          <TextField
            type="datetime-local"
            label="Data inizio"
            size="small"
            onChange={(ev) =>
              setPostObject({ ...postObject, dateFrom: ev.target.value })
            }
            value={moment(postObject.dateFrom).format(
              moment.HTML5_FMT.DATETIME_LOCAL
            )}
          />
          <TextField
            type="datetime-local"
            label="Data fine"
            size="small"
            onChange={(ev) =>
              setPostObject({ ...postObject, dateTo: ev.target.value })
            }
            value={moment(postObject.dateTo).format(
              moment.HTML5_FMT.DATETIME_LOCAL
            )}
          />
          <Button
            variant="contained"
            color="secondary"
            label="invia"
            size="small"
            onClick={() => apiHandler("POST", postObject)}
          >
            Invia
          </Button>
        </Grid>
        {scheduleData.length === 0 ? (
          <React.Fragment>
            <Grid
              container
              justifyContent={"center"}
              className={"no-data-element"}
            >
              No Data
            </Grid>
            <Grid
              container
              justifyContent={"center"}
              className={"no-data-element"}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => apiHandler("GET")}
              >
                Get Data
              </Button>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid container>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Task</TableCell>
                      <TableCell align="center">Utente</TableCell>
                      <TableCell align="center">Data inizio</TableCell>
                      <TableCell align="center">Data fine</TableCell>
                      <TableCell align="center">Modifica</TableCell>
                      <TableCell align="center">Elimina</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scheduleData.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          <TextField
                            label="Task"
                            size="small"
                            value={row.reason}
                            onChange={(ev) =>
                              changeItem(row.id, "reason", ev.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            label="Utente"
                            size="small"
                            value={row.username}
                            onChange={(ev) =>
                              changeItem(row.id, "username", ev.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            type="datetime-local"
                            label="Data fine"
                            size="small"
                            onChange={(ev) =>
                              changeItem(row.id, "dateFrom", ev.target.value)
                            }
                            value={moment(row.dateFrom).format(
                              moment.HTML5_FMT.DATETIME_LOCAL
                            )}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            type="datetime-local"
                            label="Data fine"
                            size="small"
                            onChange={(ev) =>
                              changeItem(row.id, "dateTo", ev.target.value)
                            }
                            value={moment(row.dateTo).format(
                              moment.HTML5_FMT.DATETIME_LOCAL
                            )}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton>
                            <EditIcon
                              onClick={() =>
                                apiHandler(
                                  "PUT",
                                  scheduleData.find((el) => el.id === row.id)
                                )
                              }
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => apiHandler("DELETE", { id: row.id })}
                          >
                            <DeleteIcon className={"delete-button"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </React.Fragment>
        )}
      </Paper>
    </Container>
  );
};

export default TestFantasiastore;
