import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ProfitCalc from './Container/ProfitCalc/ProfitCalc';
import Navbar from './Component/Navbar/Navbar';
import CouponGenerator from './Container/CouponGenerator/CouponGenerator';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderTotals from './Container/OrderTotals/OrderTotals';
import TestFantasiastore from './Container/TestFantasiastore/TestFantasiastore';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Navbar/>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="profit-calc" element={<ProfitCalc/>} />
      <Route path="coupon-generator" element={<CouponGenerator/>} />
      <Route path="order-totals" element={<OrderTotals/>} />
      <Route path="schedule-testfantasiastore" element={<TestFantasiastore/>} />
    </Routes>
  </BrowserRouter>
  <ToastContainer theme='colored'/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
