import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "./OrderTotals.css";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Component/Loader/Loader";
const lambdaUrl =
  "https://d6irc3pqnu7qhtwueux3kbbtvu0zoknl.lambda-url.eu-central-1.on.aws/";

const OrderTotals = (props) => {
  const [bearer, setBearer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deploySite, setDeploySite] = useState("");
  const [httpMethod, setHttpMethod] = useState("GET");
  const [orderData, setOrderData] = useState({
    prestashop: { order: [{ id: "" }] },
  });

  const axiosCall = (method, payload) => {
    console.log(method);
    const config = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    };

    if (method === "GET") {
      config.params = payload;

      axios
        .get(lambdaUrl, config)
        .then((response) => {
          setIsLoading(false);
          setOrderData(response.data.orderResp);
          setHttpMethod("PUT");
          return toast.success("Ok!");
        })
        .catch((error) => {
          setIsLoading(false);
          let message;
          typeof error.response.data.message === "string"
            ? (message = error.response.data.message)
            : (message = "Errore sconosciuto");
          return toast.error(message);
        });
    } else {
      axios
        .put(lambdaUrl, payload, config)
        .then((response) => {
          setIsLoading(false);
          setOrderData(response.data.orderResp);
          return toast.success("Ok!");
        })
        .catch((error) => {
          setIsLoading(false);
          let message;
          typeof error.response.data.message === "string"
            ? (message = error.response.data.message)
            : (message = "Errore sconosciuto");
          return toast.error(message);
        });
    }
  };

  const editableData = [
    { name: "total_products_wt", label: "Totale prodotti", type: "number" },
    { name: "total_discounts_tax_incl", label: "Totale sconti", type: "number" },
    { name: "total_shipping_tax_incl", label: "Totale Spedizione", type: "number" },
    { name: "total_paid_tax_incl", label: "Totale pagato", type: "number" },
  ];

  const handleSubmit = (ev) => {
    ev.preventDefault();

    let data = {
      site: deploySite,
      idOrder: orderData.prestashop.order[0].id,
      orderData: orderData,
    };

    setIsLoading(true);

    if (deploySite === "") {
      setIsLoading(false);
      return toast.error("Sito non selezionato");
    }

    if (bearer === "") {
      setIsLoading(false);
      return toast.error("Token mancante");
    }

    axiosCall(httpMethod, data);
  };

  return (
    <Container className={"container"}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <h1>Modifica totali ordine</h1>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel>Sito</InputLabel>
            <Select
              value={deploySite}
              label="Sito"
              onChange={(ev) => setDeploySite(ev.target.value)}
            >
              <MenuItem value={""}>Seleziona sito</MenuItem>
              <MenuItem value={"testfantasiastore"}>testfantasiastore</MenuItem>
              <MenuItem value={"fantasiastore"}>fantasiastore</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Token"
            value={bearer}
            onChange={(ev) => {
              setBearer(ev.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Id Ordine"
            type="number"
            value={orderData.prestashop.order[0].id}
            onChange={(ev) => {
              setOrderData({
                prestashop: {
                  order: [
                    {
                      ...orderData.prestashop.order[0],
                      id: ev.target.value,
                    },
                  ],
                },
              });
              setHttpMethod("GET");
            }}
          />
        </Grid>
      </Grid>
      <Paper elevation={2} className={"paper"}>
        <form onSubmit={(ev) => handleSubmit(ev)}>
          <Grid container justifyContent="center"></Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <Grid container className="buttonContainer">
              <Grid container justifyContent="center">
                {editableData.map((el, index) => {
                  return (
                    <Grid item key={index} className="input-container">
                      <TextField
                        label={el.label}
                        type={el.type}
                        value={orderData.prestashop.order[0][el.name] || ""}
                        onChange={(ev) => {
                          setOrderData({
                            ...orderData,
                            prestashop: {
                              ...orderData.prestashop,
                              order: [
                                {
                                  ...orderData.prestashop.order[0],
                                  [el.name]: ev.target.value,
                                },
                              ],
                            },
                          });
                          setHttpMethod("PUT");
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                container
                justifyContent="center"
                className="buttonContainer"
              >
                <Button
                  variant="contained"
                  color={httpMethod === "GET" ? "primary" : "secondary"}
                  type="submit"
                >
                  {httpMethod === "GET" ? "Ricevi" : "Invia"}
                </Button>
              </Grid>
              <Grid
                container
                justifyContent="center"
                className="alertContainer"
              >
                {httpMethod === "PUT" ? (
                  <h3>ATTENZIONE, stai per modificare i dati dell'ordine!</h3>
                ) : null}
              </Grid>
            </Grid>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default OrderTotals;
