import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "./CouponGenerator.css";
import { inputData } from "./inputData";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "../../Component/Loader/Loader";
import { DiscountSwitch } from "../../Component/DiscountSwitch/DiscountSwitch";
const lambdaUrl =
  "https://gykv5o4uvfpfo6noesfgid757i0kuugv.lambda-url.eu-central-1.on.aws/";

const generateField = (data, key) => {
  switch (data.type) {
    case "string":
      return (
        <Grid item xs={3} key={key} className={"custom-input-grid"}>
          <TextField
            label={data.label}
            name={data.name}
            required={data.required}
            defaultValue={data.defaultValue ? data.defaultValue : null}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid item xs={3} key={key} className={"custom-input-grid"}>
          <TextField
            label={data.label}
            name={data.name}
            required={data.required}
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    case "bool":
      if (data.name === "reduction_switch") {
        return <DiscountSwitch data={data} key={key}></DiscountSwitch>;
      } else {
        return (
          <Grid item xs={3} key={key} className={"custom-input-grid"}>
            <FormControlLabel
              required={data.required}
              control={
                <Switch defaultChecked={data.defaultValue} name={data.name} />
              }
              label={data.label}
            />
          </Grid>
        );
      }
    case "int":
      return (
        <Grid item xs={3} key={key} className={"custom-input-grid"}>
          <TextField
            label={data.label}
            name={data.name}
            type="number"
            required={data.required}
            className={"custom-input"}
            InputProps={{
              inputProps: {
                min: data.min ? data.min : 0,
                max: data.max ? data.max : 10,
              },
            }}
            defaultValue={data.defaultValue ? data.defaultValue : null}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    //case "float":
    //return <DiscountBox data={data} key={key}></DiscountBox>;
    default:
      break;
  }
};

const CouponGenerator = (props) => {
  const [bearer, setBearer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deploySite, setDeploySite] = useState("");

  const handleSubmit = (ev) => {
    ev.preventDefault();

    let data = {
      coupon: {},
      prestashop: {},
      deploySite: "",
    };

    setIsLoading(true);

    if (deploySite === "") {
      setIsLoading(false);
      return toast.error("Sito non selezionato");
    }

    data.deploySite = deploySite;

    if (bearer === "") {
      setIsLoading(false);
      return toast.error("Token mancante");
    }

    let collection = ev.target.querySelectorAll("input");

    const fillData = (key, value) => {
      switch (key) {
        case "couponNumber":
          data.coupon[key] = value;
          break;
        case "prefix":
          data.coupon[key] = value;
          break;
        case "stringLength":
          data.coupon[key] = value;
          break;
        default:
          data.prestashop[key] = value;
          break;
      }
    };

    collection.forEach((el) => {
      switch (el.type) {
        case "checkbox":
          fillData(el.name, el.checked ? "1" : "0");
          break;
        case "datetime-local":
          fillData(el.name, moment(el.value).format("YYYY-MM-DD HH:mm:ss"));
          break;
        default:
          fillData(el.name, el.value);
          break;
      }
    });

    const config = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    };

    axios
      .post(lambdaUrl, data, config)
      .then((response) => {
        setIsLoading(false);
        if (response.data.csv) {
          var a = document.createElement("a");
          a.href = "data:application/octet-stream;base64," + response.data.csv;
          a.download = "coupon.csv"; //File name Here
          a.click(); //Downloaded file
        }
        return toast.success("Ok!");
      })
      .catch((error) => {
        setIsLoading(false);
        return toast.error(error.response.data.error);
      });
  };

  return (
    <Container className={"container"}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <h1>Generatore codici sconto</h1>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel>Sito</InputLabel>
            <Select
              value={deploySite}
              label="Sito"
              onChange={(ev) => setDeploySite(ev.target.value)}
            >
              <MenuItem value={""}>Seleziona sito</MenuItem>
              <MenuItem value={"testfantasiastore"}>testfantasiastore</MenuItem>
              <MenuItem value={"fantasiastore"}>fantasiastore</MenuItem>
              <MenuItem value={"pastrastore.com"}>pastrastore.com</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Token"
            defaultValue={bearer}
            onChange={(ev) => {
              setBearer(ev.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper elevation={2} className={"paper"}>
        <form onSubmit={(ev) => handleSubmit(ev)}>
          <Grid container justifyContent="center">
            {inputData.map((el, index) => {
              return generateField(el, index);
            })}
          </Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <Grid container className="buttonContainer" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="submit"
              >
                Invia
              </Button>
            </Grid>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default CouponGenerator;
