import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import EuroIcon from "@mui/icons-material/Euro";
import PercentIcon from "@mui/icons-material/Percent";
import "./ProfitCalc.css";
import { profitTableData } from "./profitTableData";
import { useState } from "react";

const ProfitCalc = () => {
  const [userValue, setUserValue] = useState({
    purchasePrice: 21,
    sellPrice: 11,
    vat: 22,
    shippingCosts: 5,
  });

  const returnCalc = () => {
    console.log(userValue);
    const VATpercentage = (parseInt(userValue.vat) + parseInt(100)) / 100;

    const sellVat = Math.abs(
      userValue.sellPrice / VATpercentage - userValue.sellPrice
    );
    const buyVAT =
      userValue.purchasePrice * VATpercentage - userValue.purchasePrice;
    const netProfit =
      userValue.sellPrice -
      userValue.purchasePrice -
      sellVat -
      userValue.shippingCosts;

    const profitPercentage = (netProfit / userValue.sellPrice) * 100;
    const netCommissionsAmz = userValue.sellPrice * 0.15;
    const netCommissionsEbay = userValue.sellPrice * 0.1;
    const netCommissionsIBS = userValue.sellPrice * 0.1;
    const netCommissionsStripe = userValue.sellPrice * 0.014 + 0.25;
    const netCommissionsPaypal = userValue.sellPrice * 0.034 + 0.35;
    const amzProfit = netProfit - netCommissionsAmz;
    const amzProfitPercentage = (amzProfit / userValue.sellPrice) * 100;
    const ebayProfit = netProfit - netCommissionsEbay;
    const ebayProfitPercentage = (ebayProfit / userValue.sellPrice) * 100;
    const ibsProfit = netProfit - netCommissionsIBS;
    const ibsProfitPercentage = (ibsProfit / userValue.sellPrice) * 100;
    const websitePaypalProfit = netProfit - netCommissionsPaypal;
    const websitePaypalProfitPercentage =
      (websitePaypalProfit / userValue.sellPrice) * 100;
    const websiteStripeProfit = netProfit - netCommissionsStripe;
    const websiteStripeProfitPercentage =
      (websiteStripeProfit / userValue.sellPrice) * 100;

    return {
      purchasePrice: userValue.purchasePrice.toFixed(2),
      sellPrice: userValue.sellPrice.toFixed(2),
      shippingCosts: userValue.shippingCosts.toFixed(2),
      sellVat: sellVat.toFixed(2),
      buyVAT: buyVAT.toFixed(2),
      VATtotal: (sellVat - buyVAT).toFixed(2),
      netProfit: netProfit.toFixed(2),
      profitPercentage: profitPercentage.toFixed(2),
      netCommissionsAmz: netCommissionsAmz.toFixed(2),
      netCommissionsEbay: netCommissionsEbay.toFixed(2),
      netCommissionsIBS: netCommissionsIBS.toFixed(2),
      netCommissionsStripe: netCommissionsStripe.toFixed(2),
      netCommissionsPaypal: netCommissionsPaypal.toFixed(2),
      amzProfit: amzProfit.toFixed(2),
      amzProfitPercentage: amzProfitPercentage.toFixed(2),
      ebayProfit: ebayProfit.toFixed(2),
      ebayProfitPercentage: ebayProfitPercentage.toFixed(2),
      ibsProfit: ibsProfit.toFixed(2),
      ibsProfitPercentage: ibsProfitPercentage.toFixed(2),
      websitePaypalProfit: websitePaypalProfit.toFixed(2),
      websitePaypalProfitPercentage: websitePaypalProfitPercentage.toFixed(2),
      websiteStripeProfit: websiteStripeProfit.toFixed(2),
      websiteStripeProfitPercentage: websiteStripeProfitPercentage.toFixed(2),
    };
  };

  const tableValues = returnCalc();

  return (
    <Container className={"container"}>
      <Grid container spacing={1}>
        <h1>Calcolatrice Profitto</h1>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <TextField
            label="Prezzo di Acquisto"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              ),
            }}
            type="number"
            defaultValue={
              isNaN(userValue.purchasePrice) ? "" : userValue.purchasePrice
            }
            onChange={(ev) =>
              setUserValue({
                ...userValue,
                purchasePrice: parseFloat(ev.target.value),
              })
            }
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Prezzo di Vendita"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              ),
            }}
            type="number"
            size="small"
            onChange={(ev) =>
              setUserValue({
                ...userValue,
                sellPrice: parseFloat(ev.target.value),
              })
            }
            defaultValue={isNaN(userValue.sellPrice) ? "" : userValue.sellPrice}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Iva"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            type="number"
            onChange={(ev) =>
              setUserValue({ ...userValue, vat: parseFloat(ev.target.value) })
            }
            defaultValue={isNaN(userValue.vat) ? "" : userValue.vat}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Costi spedizione"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            type="number"
            onChange={(ev) =>
              setUserValue({
                ...userValue,
                shippingCosts: parseFloat(ev.target.value),
              })
            }
            defaultValue={
              isNaN(userValue.shippingCosts) ? "" : userValue.shippingCosts
            }
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container className={"table-container"}>
        <Grid item xs={6} className={"my-table"}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {profitTableData.firstTable.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell>{row.label}</TableCell>
                    <TableCell align="left">{tableValues[row.value]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6} className={"my-table"}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {profitTableData.secondTable.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell>{row.label}</TableCell>
                    <TableCell align="left">{tableValues[row.value]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={6} className={"my-table"}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  {"Vendita su Amazon: guadagno totale " +
                    tableValues.amzProfit +
                    " €, margine del " +
                    tableValues.amzProfitPercentage +
                    " %"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {"Vendita sullo shop(Paypal): guadagno totale " +
                    tableValues.websitePaypalProfit +
                    ", margine del " +
                    tableValues.websitePaypalProfitPercentage +
                    " %"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {"Vendita sullo shop(Stripe): guadagno totale " +
                    tableValues.websiteStripeProfit +
                    " €, margine del " +
                    tableValues.websiteStripeProfitPercentage +
                    " %"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {"Vendita su eBay: guadagno totale " +
                    tableValues.ebayProfit +
                    " €, margine del " +
                    tableValues.ebayProfitPercentage +
                    " %"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {"Vendita su IBS: guadagno totale " +
                    tableValues.ibsProfit +
                    " €, margine del " +
                    tableValues.ibsProfitPercentage +
                    " %"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Container>
  );
};

export default ProfitCalc;
